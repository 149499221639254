import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export type ModalCloseButtonProps = {
    onClose: () => void;
};

const ModalCloseButton = ({ onClose }: ModalCloseButtonProps) => {
    return (
        <button className="text-gray-300 border-none border-0 outline-none" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
        </button>
    );
};

export default ModalCloseButton;

import { GoogleOAuthProvider, TokenResponse, useGoogleLogin } from "@react-oauth/google";
import Button from "../Button";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

type GoogleLoginButtonProps = {
    onClick: (tokenId: string) => void;
    className?: string;
    label: string;
};

const CustomGoogleLogin = ({ onClick, className, label }: GoogleLoginButtonProps) => {
    const handleResponse = (response: TokenResponse) => {
        const tokenId = response.access_token;
        if (onClick) {
            onClick(tokenId);
        }
    };

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => handleResponse(tokenResponse),
        onError: (errorResponse) => {
            console.error("Google Login Error:", errorResponse);
        },
        flow: "implicit",
    });

    return (
        <Button
            className={`border-google text-google hover:bg-google hover:text-white ${className}`}
            onClick={() => login()}
            icon={faGoogle}
            variant="transparent"
        >
            {label}
        </Button>
    );
};

const GoogleLoginButton = ({ onClick, className, label }: GoogleLoginButtonProps) => {
    return (
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ""}>
            <CustomGoogleLogin onClick={onClick} className={className} label={label} />
        </GoogleOAuthProvider>
    );
};


export default GoogleLoginButton;

"use client";

import Modal from "../Modal";
import { useTranslation } from "@/lib/i18n/client";
import { useLanguage } from "@/lib/hooks/useLanguage";
import ModalCloseButton from "../Modal/ModalCloseButton";
import { useAtom } from "jotai";
import { isLoginModalShownAtom } from "@/lib/jotai/auth/authStore";
import LoginForm from "../Forms/LoginForm";
import CloudImage from "../CloudImage";
import { CLOUDINARY_FOLDER } from "@/lib/constants/cloudinary";

export default function Login() {
    const [open, setOpen] = useAtom(isLoginModalShownAtom);
    const lang = useLanguage();
    const { t } = useTranslation(lang, "login");

    return (
        <div className="text-3xl font-bold underline flex flex-col">
            <Modal open={open} mode="windowed" onClose={() => setOpen(false)}>
                <div className="flex w-full">
                    <div className="relative w-1/2 max-sm:hidden">
                        <CloudImage
                            src={`${CLOUDINARY_FOLDER}/static/login.jpg`}
                            alt=""
                            className="h-full object-cover bg-gray-500"
                            sizes="50vw"
                        />
                        <div className="absolute top-1/2 -translate-y-1/2 px-8 flex flex-col font-bold lg:text-[40px] text-3xl text-left text-white">
                            <p>{t("welcome-back")}</p>
                            <p>{t("please-login")}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 sm:w-1/2 w-full sm:p-8 px-4 py-8">
                        <div className="flex w-full items-center justify-between">
                            <div className="font-bold text-xl">{t("login")}</div>
                            <ModalCloseButton onClose={() => setOpen(false)} />
                        </div>
                        <LoginForm isInModal />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

"use client";
import { GetRoomCategoryPriceResponse, RoomUnavailableDays } from "@/lib/types/Search";
import React, { createContext, useState } from "react";

interface PriceContextType {
    pricingSaved: GetRoomCategoryPriceResponse | null;
    roomId: string;
    unavailableDaysSaved: RoomUnavailableDays | null;
    setRoomId: (newSettings: string) => void;
    updatePricing: (newSettings: GetRoomCategoryPriceResponse | null) => void;
    handleUnAvailableDays: (value: RoomUnavailableDays) => void;
    diffPrices: GetRoomCategoryPriceResponse[];
    setDiffPrices: (newSettings: GetRoomCategoryPriceResponse[]) => void;
}

export const PriceContext = createContext<PriceContextType>({
    pricingSaved: null,
    roomId: "",
    unavailableDaysSaved: null,
    setRoomId: () => {},
    updatePricing: () => {},
    handleUnAvailableDays: () => {},
    diffPrices: [],
    setDiffPrices: () => {},
});

interface PricingProviderProps {
    children: React.ReactNode;
}

export const PricingProvider: React.FC<PricingProviderProps> = ({ children }) => {
    const [pricing, setPricing] = useState<GetRoomCategoryPriceResponse | null>(null);
    const [roomId, setRoomId] = useState<string>("");
    const [unavailableDays, setUnavailableDays] = useState<RoomUnavailableDays | null>(null);
    // const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const [diffPrices, setDiffPrices] = useState<GetRoomCategoryPriceResponse[]>([]);

    const updatePricing = (newPricing: GetRoomCategoryPriceResponse | null) => {
        setPricing(newPricing);
    };

    const handleUnAvailableDays = (value: RoomUnavailableDays) => {
        setUnavailableDays(value);
    };

    // useEffect(() => {
    //     const undesiredPaths = ["/checkout"];
    //     const checkPathChange = () => {
    //         const newPath = window.location.pathname;
    //         console.log(newPath, " newPAth");
    //         console.log(window.location.pathname, "window.location.pathname");
    //         if (newPath !== currentPath) {
    //             setCurrentPath(newPath);
    //             if (!undesiredPaths.includes(newPath)) {
    //                 setPricing(null);
    //                 setRoomId("");
    //                 setUnavailableDays(null);
    //             }
    //         }
    //     };

    //     checkPathChange();

    //     window.addEventListener("popstate", checkPathChange);

    //     return () => {
    //         window.removeEventListener("popstate", checkPathChange);
    //     };
    // }, [currentPath]);

    return (
        <PriceContext.Provider
            value={{
                pricingSaved: pricing,
                updatePricing,
                roomId,
                setRoomId,
                unavailableDaysSaved: unavailableDays,
                handleUnAvailableDays,
                diffPrices,
                setDiffPrices,
            }}
        >
            {children}
        </PriceContext.Provider>
    );
};

"use client";

import Script from "next/script";
import { useEffect, useState } from "react";
import { getGTMCode } from "../utils/general";
import { LanguageParams } from "../types/PageProps";
import { useAtomValue } from "jotai";
import { authAtom } from "../jotai/auth/authStore";
import { getCookiePreferences } from "../utils/cookies";

const GoogleAnalytics = ({ lang }: LanguageParams) => {
    const { analyticsEnabled } = useAtomValue(authAtom);
    const [statisticsEnabled, setStatisticsEnabled] = useState(analyticsEnabled ?? false);
    const [GTMCode, setGTMCode] = useState<boolean | string>(false);

    useEffect(() => {
        const currentGTMCode = getGTMCode(lang);
        if (currentGTMCode) {
            setGTMCode(currentGTMCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cookies = getCookiePreferences();
        if (analyticsEnabled || cookies.includes("statistics")) setStatisticsEnabled(true);
    }, [analyticsEnabled]);

    return statisticsEnabled ? (
        <>
            {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ? (
                <>
                    <Script
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                    />
                    <Script id="google-analytics" strategy="afterInteractive">
                        {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {page_path: window.location.pathname,);`}
                    </Script>
                </>
            ) : null}
            {GTMCode ? (
                <>
                    <noscript>
                        <iframe
                            src={`https://www.googletagmanager.com/ns.html?id=${GTMCode}`}
                            height="0"
                            width="0"
                            style={{ display: "none", visibility: "hidden" }}
                        ></iframe>
                    </noscript>
                    <Script id="google-tag-manager" strategy="afterInteractive">
                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','${GTMCode}');`}
                    </Script>
                </>
            ) : null}
        </>
    ) : null;
};

export default GoogleAnalytics;

import { useLanguage } from "@/lib/hooks/useLanguage";
import Button from "../../Button";
import { useTranslation } from "@/lib/i18n/client";
import { Field, Form, Formik } from "formik";
import { API, LoginFormData } from "@/lib/services/authService";
import { useState } from "react";
import Alert from "../../Alert";
import { useAtomValue, useSetAtom } from "jotai";
import { authAtom, isForgotPasswordModalShownAtom, isLoginModalShownAtom } from "@/lib/jotai/auth/authStore";
import Input from "../../Input";
import * as Yup from "yup";
import { TFunction } from "next-i18next";
import Link from "next/link";
import { Urls, getUrl } from "@/lib/constants/urls";
import GoogleLoginButton from "../../Auth/GoogleLoginButton";
import { usePathname } from "next/navigation";
import { useRegisterNextParams } from "@/lib/hooks/useRegisterNextParams";
import { UserInfo } from "@/lib/jotai/auth/types";
import { SITES, getBackend } from "@/sites";
import { ENDPOINTS } from "@/lib/constants/endpoints";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

const LoginSchema = (translateFun: TFunction) => {
    return Yup.object().shape({
        email: Yup.string()
            .email(translateFun("invalid.email") || "")
            .required(translateFun("field-required") || ""),
        password: Yup.string().required(translateFun("field-required") || ""),
    });
};

type LoginFormProps = {
    isInModal?: boolean;
    onGoToRegisterButtonClick?: () => void;
};

const LoginForm = ({ onGoToRegisterButtonClick, isInModal = false }: LoginFormProps) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "login");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const initialValues: LoginFormData = {
        email: "",
        password: "",
    };
    const setAuth = useSetAtom(authAtom);
    const auth = useAtomValue(authAtom);
    const showLoginModal = useSetAtom(isLoginModalShownAtom);
    const setOpenForgotPasswordModal = useSetAtom(isForgotPasswordModalShownAtom);
    const pathname = usePathname();
    const registerParams = useRegisterNextParams();
    const elsePaageType = isInModal ? "header" : "sign_up_page";

    const getRegistrationUrl = () => {
        const registerUrl = getUrl(Urls.register.index, lang);
        if (pathname === registerUrl) {
            return registerUrl;
        }
        return getUrl(Urls.register.index, lang, null, registerParams);
    };

    const redirectToGuestPanel = async (userInfo: UserInfo) => {
        if (userInfo.kind === "Host") {
            const currentBackendHost = getBackend(lang);
            if (currentBackendHost !== SITES.hostPanelBackend) {
                const tokenResponse = await API.SendStorage({
                    userInfo: userInfo,
                    redirectUri: process.env.NEXT_PUBLIC_HOST_PANEL ?? "",
                    checkoutForms: {},
                });

                let endpoint = ENDPOINTS.HOST_TOKEN_LOGIN;
                if (endpoint.startsWith("/")) {
                    endpoint = endpoint.replace("/", "");
                }

                window.location.href = `${SITES.hostPanelBackend}${endpoint}?token=${tokenResponse.token}`;
                return;
            }

            window.location.href = process.env.NEXT_PUBLIC_HOST_PANEL ?? "";
        }
    };

    const onSubmit = async (values: LoginFormData) => {
        try {
            const userInfo = await API.Login(values);
            setAuth({
                ...auth,
                isLoggedIn: true,
                userInfo,
            });
            showLoginModal(false);
            GoogleTagManagerEvents.submitLoginPageInfo(
                lang,
                onGoToRegisterButtonClick ? "checkout" : elsePaageType,
                "Direct",
                userInfo._id,
            );
            redirectToGuestPanel(userInfo);
        } catch (err) {
            if (err instanceof Error) {
                setErrorMessage(err.message);
            }
        }
    };

    const onWithGoogle = async (googleTokenId: string) => {
        try {
            const userInfo = await API.GoogleLogin(googleTokenId);
            setAuth({
                ...auth,
                isLoggedIn: true,
                userInfo,
            });
            GoogleTagManagerEvents.submitLoginPageInfo(
                lang,
                onGoToRegisterButtonClick ? "checkout" : "sign_up_page",
                "Google",
                userInfo._id,
            );
            showLoginModal(false);
            redirectToGuestPanel(userInfo);
        } catch (err) {
            if (err instanceof Error) {
                setErrorMessage(err.message);
            }
        }
    };

    return (
        <div className="flex flex-col">
            {/* LOGIN PROVIDERS */}
            <div className="flex flex-col gap-2 pb-8">
                {/* <div className="min-w-full sm:p-3 p-2 rounded-md text-grayAlpha-500 border border-grayAlpha-500 text-center text-sm font-semibold">
                                <span>
                                    {t("login-facebook")}
                                </span>
                            </div>
                            <div className="min-w-full sm:p-3 p-2 rounded-md text-facebook border border-facebook  text-center text-sm font-semibold">
                                <span className="align-middle">
                                    {t("login-apple")}

                                </span>
                            </div> */}
                <GoogleLoginButton label={t("login-google")} onClick={onWithGoogle} />
            </div>
            <div className="flex flex-col gap-5">
                <div className="w-full font-bold text-lg text-center">{t("login-email")}</div>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginSchema(t)}>
                    {({ errors, isSubmitting }) => (
                        <Form className="flex flex-col gap-3">
                            <div>
                                <Field
                                    as={Input}
                                    useDefaultBorderStyles={false}
                                    id="email"
                                    name="email"
                                    type={"email"}
                                    placeholder="Email"
                                    className={"w-full text-sm border h-10 p-2 rounded-t-md"}
                                    errorMessage={errors.email}
                                />
                                <Field
                                    as={Input}
                                    useDefaultBorderStyles={false}
                                    id="password"
                                    name="password"
                                    type={"password"}
                                    placeholder="Password"
                                    className={"w-full text-sm border h-10 p-2 rounded-b-md"}
                                    errorMessage={errors.password}
                                />
                            </div>
                            <div className="flex w-full justify-end items-center">
                                <div
                                    className="text-sm text-gray-500 underline cursor-pointer"
                                    onClick={() => {
                                        GoogleTagManagerEvents.clickForgetPassword(
                                            lang,
                                            onGoToRegisterButtonClick ? "checkout" : elsePaageType,
                                        );
                                        setOpenForgotPasswordModal(true);
                                    }}
                                >
                                    {t("forgot-password")}
                                </div>
                            </div>
                            {errorMessage && <Alert messages={[errorMessage]} />}
                            <Button
                                disabled={isSubmitting}
                                isLoading={isSubmitting}
                                variant="orange"
                                type="submit"
                                size="custom"
                                className="font-bold h-12"
                            >
                                {t("login")}
                            </Button>
                        </Form>
                    )}
                </Formik>
                <div className="text-sm text-left">
                    {t("no-account")}
                    {!onGoToRegisterButtonClick ? (
                        <Link
                            href={getRegistrationUrl()}
                            onClick={() => {
                                GoogleTagManagerEvents.gotoRegisterPageClick(lang, "login_popup");
                                showLoginModal(false);
                            }}
                        >
                            <span className="text-primary-500 underline px-2">{t("register")}</span>
                        </Link>
                    ) : (
                        <button
                            onClick={() => {
                                GoogleTagManagerEvents.gotoRegisterPageClick(lang, "checkout");
                                showLoginModal(false);
                                onGoToRegisterButtonClick();
                            }}
                        >
                            <span className="text-primary-500 underline px-2">{t("register")}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginForm;

export const ENDPOINTS = {
    LOGIN: "/account/session",
    GOOGLE_LOGIN: "/account/session-google",
    VERIFY_EMAIL: "/account/email-verification/{token}",
    FORGOT_PASSWORD: "/account/forgot-password",
    CHANGE_PASSWORD: "/account/password",
    RESET_PASSWORD: "/account/reset-password",
    USERINFO: "/account",
    REGISTER_GUEST: "/account/guest",
    STORAGE: "/account/storage",
    TOKEN_LOGIN: "/account/token-login",
    HOST_TOKEN_LOGIN: "/account/host-token-login",
    UPLOAD: "/upload",
    DELETE_PROFILE_IMAGE: "/profile-picture",
    UPDATEUSERPROFILE: "/user",
    SEARCH: "/search",
    ROOM_CATEGORY: "/room-category",
    ROOM_CATEGORY_NEARBY: "/room-category/{roomId}/nearby",
    CONVERSATION: "/conversation",
    MESSAGES: "/messages",
    STRIPE_PAYMENT_METHODS: "/payment/stripe/payment-method",
    STRIPE_PAYMENT_INTENT: "/payment/stripe/payment-intent",
    RESERVATION: "/reservation",
    INVOICE: "/invoice",
    PHONE_VALIDATION: "/account/phone-number-verification",
    SEARCH_ROOM_PRICE: "/search/price",
    SEARCH_ROOM_CALENDAR: "/search/calendar",
    STRIPE_PAYMENT_CHECKOUT: "/payment/stripe/checkout",
    PAYPAL_PAYMENT_WALLET_CHECKOUT: "/payment/paypal/checkout/wallet/capture",
    PAYPAL_PAYMENT_CHECKOUT: "/payment/paypal/checkout",
    PAYPAL_CAPTURE_INSTANT_CHECKOUT: "/payment/paypal/checkout/capture",
    PAYPAL_STORE_REQUEST_CHECKOUT: "/payment/paypal/checkout/store",
    // Initiates the setup for creating the payment methdo
    PAYPAL_CREATE_SETUP_TOKEN: "/payment/paypal/setup-token",
    // Captures the token from the URL query params and actually creates and stores the payment method
    PAYPAL_CREATE_PAYMENT_TOKEN: "/payment/paypal/payment-token",
    // Fetch all PayPal stored payment methods for given user
    PAYPAL_GET_PAYMENT_METHODS: "/payment/paypal/payment-token",
    REGISTER_HOST: "/account/host",
    PROPERTY_INFO: "/property/{propertyId}/info",
};

import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

type ButtonVariant = "orange" | "transparent" | "black" | "green" | "white" | "flatWhite" | "custom";
type ButtonSize = "md" | "lg" | "custom";
type FontSize = "xxs" | "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl";
type IconDirection = "left" | "right";
type IconProp = React.ComponentProps<typeof FontAwesomeIcon>["icon"];

type Props = {
    variant?: ButtonVariant;
    size?: ButtonSize;
    fontSize?: FontSize;
    icon?: IconProp | null;
    direction?: IconDirection;
    isLoading?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, Props>(
    (
        {
            fontSize = "base",
            size = "lg",
            variant = "orange",
            icon,
            direction = "left",
            className,
            isLoading = false,
            ...rest
        },
        ref,
    ) => {
        let variantCss = "";
        switch (variant) {
            case "green":
                variantCss = "text-white bg-green-500 hover:bg-green-700";
                break;
            case "black":
                variantCss =
                    "text-white bg-black hover:bg-tranparent hover:text-white hover:border-2 hover:border-solid hover:border-black";
                break;
            case "orange":
                variantCss = "text-white bg-primary-500 hover:bg-primary-400";
                break;
            case "transparent":
                variantCss = "bg-transparent text-black hover:text-primary-500 border-2 border-solid border-black";
                break;
            case "white":
                variantCss = "bg-white text-black hover:bg-gray-200 border solid-border border-black";
                break;
            case "flatWhite":
                variantCss = "bg-white text-black hover:bg-black hover:text-white";
                break;
            default:
        }

        let sizeCss = "";
        switch (size) {
            case "md":
                sizeCss = "h-11";
                break;
            case "lg":
                sizeCss = "h-14";
                break;
            default:
        }

        const fontSizeCss = `text-${fontSize}`;

        const getFlexDirection = (direction: IconDirection) => {
            return direction === "left" ? "flex-row" : "flex-row-reverse";
        };

        const renderIcon = () => (icon ? <FontAwesomeIcon icon={icon} /> : null);
        const flexDirection = getFlexDirection(direction);

        return (
            <button
                className={`${sizeCss} ${fontSizeCss} rounded-md ${variantCss} ${className} disabled:opacity-60 disabled:cursor-not-allowed`}
                {...rest}
                ref={ref}
            >
                <div className={`flex gap-2 ${flexDirection} items-center justify-center whitespace-nowrap ${isLoading ? "ml-2" : ""}`}>
                    {renderIcon()}
                    {rest.children}
                    {isLoading && (
                        <FontAwesomeIcon icon={faSpinner} size="lg" className="mx-2 animate-spin text-center" />
                    )}
                </div>
            </button>
        );
    },
);

Button.displayName = "Button";
export default Button;

export function getCookie(name: string) {
    if (typeof document === "undefined") return;
    return document.cookie.split(";").filter(x => x.trim().startsWith(`${name}=`))[0]?.trim().split("=")[1];
}

export function getCookiePreferences() {
    const cookie = getCookie("cookiePreferences");
    if (!cookie) return [];
    return decodeURIComponent(cookie).split(",");
}

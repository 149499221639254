import Modal from "../Modal";
import Text from "../Text";
import Button from "../Button";
import { useTranslation } from "@/lib/i18n/client";
import { useState, useEffect, MouseEventHandler } from "react";
import { CheckBoxLabelTranslations, CheckboxesStates, CookieCheckbokForm } from "./types";

type CookieConsentModalProps = {
    lang: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setShowCookieBanner: (newState: boolean) => void;
    checkBoxForm: CookieCheckbokForm;
    checkboxesLabels: CheckBoxLabelTranslations;
    handleSubmit: (checkBoxFormData: CookieCheckbokForm) => void;
    handleApproveAll: () => void;
    setCheckBoxState: (newState: CookieCheckbokForm) => void;
};

const CookieConsentModal = (props: CookieConsentModalProps) => {
    const { lang, isOpen, checkboxesLabels, checkBoxForm, handleSubmit, handleApproveAll, setCheckBoxState } = props;
    const { t } = useTranslation(lang, "cookie-banner");
    const [essentialsIsChecked, setEssentialsIsChecked] = useState(false);

    /**
     * Handles the setting of values in the local storage
     * also handles disabling and enabling the submit buttons
     *
     * @param e React.MouseEvent
     */
    const handleCheckBox = (e: React.MouseEvent | React.ChangeEvent): void => {
        const targetHTMLObject = e.target as HTMLInputElement;
        const checkboxName = targetHTMLObject.id;
        const checkboxValue = targetHTMLObject.checked ? CheckboxesStates.Accepted : CheckboxesStates.Rejected;
        setCheckBoxState({
            ...checkBoxForm,
            [`${checkboxName}`]: checkboxValue,
        });
    };

    /**
     * Trigger same behaviour of clicking checkbox when you click
     * the detailed explanation of a cookie setting
     *
     * @param e React.MouseEvent & { target: HTMLDivElement }
     */
    const triggerSiblingCheckbox = (e: MouseEventHandler & { target: HTMLDivElement }) => {
        ((e.target as HTMLDivElement)?.previousElementSibling as HTMLElement)?.click();
    };

    /**
     * Tracking changes on the checkBoxesForm especially on the essential
     * propprety to stop the submit buttons from being active
     */
    useEffect(() => {
        if (checkBoxForm.essential !== CheckboxesStates.Unknown) {
            const booleanValue = checkBoxForm.essential === CheckboxesStates.Accepted;
            setEssentialsIsChecked(booleanValue);
        }
    }, [checkBoxForm.essential]);

    return (
        <Modal
            open={isOpen}
            setOpen={() => {
                /* do nothing */
            }}
            mode="windowed"
            useDefaultContentPadding
            data-testid="cookie-banner"
        >
            <div className="flex flex-col px-1 pb-1 md:px-8 md:pb-8">
                <div className="flex grow flex-col justify-between">
                    <div>
                        <Text className="text-left mt-2 md:mt-8" as="h4">
                            {t("cookieSettingsScreen.title")}
                        </Text>
                        <Text className="block text-left text-base md:text-xl font-medium mt-1 md:mt-3.5 mb-1 md:mb-8">
                            {t("cookieSettingsScreen.text")}
                        </Text>
                        <div className="grid grid-cols-10 gap-2 md:gap-5 items-center">
                            <div className=" flex flex-wrap text-md col-span-5 h-full w-full max-sm:col-span-10 hover:cursor-pointer">
                                <input
                                    className="mr-1 mb-1 mt-1 md:mt-3.5 p-2.5 inline-block max-h-4 checked:bg-gray-500 hover:checked:bg-gray-500 focus:bg-gray-500"
                                    name="essential"
                                    id="essential"
                                    type={"checkbox"}
                                    checked={checkBoxForm.essential === CheckboxesStates.Accepted}
                                />
                                <label
                                    className="mt-1.5 text-base md:text-xl inline-block font-medium  hover:cursor-pointer"
                                    htmlFor="essential"
                                >
                                    {t("cookieSettingsScreen.checkBoxLabels.essentialText")}
                                </label>
                                <div
                                    className="text-left text-base md:text-xl"
                                >
                                    {checkboxesLabels.essentialText}
                                </div>
                            </div>
                            <div className=" flex flex-wrap text-md col-span-5 h-full w-full max-sm:col-span-10 hover:cursor-pointer">
                                <input
                                    className="mr-1 mb-1 mt-1 md:mt-3.5 p-2.5 inline-block max-h-4 checked:bg-black hover:checked:bg-black"
                                    name="statistics"
                                    id="statistics"
                                    type={"checkbox"}
                                    onClick={handleCheckBox}
                                    onChange={handleCheckBox}
                                    checked={checkBoxForm.statistics === CheckboxesStates.Accepted}
                                />
                                <label
                                    className="mt-1.5 text-base md:text-xl inline-block font-medium hover:cursor-pointer"
                                    htmlFor="statistics"
                                >
                                    {t("cookieSettingsScreen.checkBoxLabels.statisticsText")}
                                </label>
                                <div
                                    onClick={(e) =>
                                        triggerSiblingCheckbox(
                                            e as unknown as MouseEventHandler & { target: HTMLDivElement },
                                        )
                                    }
                                    className="text-left text-base md:text-xl"
                                >
                                    {checkboxesLabels.statisticsText}
                                </div>
                            </div>
                            <div className=" flex flex-wrap text-md col-span-5 h-full w-full max-sm:col-span-10 hover:cursor-pointer">
                                <input
                                    className="mr-1 mb-1 mt-1 md:mt-3.5 p-2.5 inline-block max-h-4 checked:bg-black hover:checked:bg-black"
                                    name="marketing"
                                    id="marketing"
                                    type={"checkbox"}
                                    onClick={handleCheckBox}
                                    onChange={handleCheckBox}
                                    checked={checkBoxForm.marketing === CheckboxesStates.Accepted}
                                />
                                <label
                                    className="mt-1.5  text-base md:text-xl inline-block font-medium hover:cursor-pointer"
                                    htmlFor="marketing"
                                >
                                    {t("cookieSettingsScreen.checkBoxLabels.marketingText")}
                                </label>
                                <div
                                    onClick={(e) =>
                                        triggerSiblingCheckbox(
                                            e as unknown as MouseEventHandler & { target: HTMLDivElement },
                                        )
                                    }
                                    className="text-left text-base md:text-xl"
                                >
                                    {checkboxesLabels.marketingText}
                                </div>
                            </div>
                            <div className=" flex flex-wrap text-md col-span-5 h-full w-full max-sm:col-span-10 hover:cursor-pointer">
                                <input
                                    className="mr-1 mb-1 mt-1 md:mt-3.5 p-2.5 inline-block max-h-4 checked:bg-black hover:checked:bg-black"
                                    name="external-media"
                                    id="external-media"
                                    type={"checkbox"}
                                    onClick={handleCheckBox}
                                    onChange={handleCheckBox}
                                    checked={checkBoxForm["external-media"] === CheckboxesStates.Accepted}
                                />
                                <label
                                    className="mt-1.5  text-base md:text-xl inline-block font-medium hover:cursor-pointer"
                                    htmlFor="external-media"
                                >
                                    {t("cookieSettingsScreen.checkBoxLabels.externalMediaText")}
                                </label>
                                <div
                                    onClick={(e) =>
                                        triggerSiblingCheckbox(
                                            e as unknown as MouseEventHandler & { target: HTMLDivElement },
                                        )
                                    }
                                    className="text-left text-base md:text-xl"
                                >
                                    {checkboxesLabels.externalMediaText}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-1 flex-wrap mt-3 md:mt-14">
                        <Button
                            disabled={!essentialsIsChecked}
                            className="font-medium grow max-sm:mb-4"
                            variant="transparent"
                            size="lg"
                            fontSize="base"
                            onClick={() => handleSubmit(checkBoxForm)}
                        >
                            {t("cookieSettingsScreen.sendButton")}
                        </Button>
                        <Button
                            className="font-bold grow sm:ml-4 md:ml-4 lg:ml-4"
                            variant="orange"
                            size="lg"
                            fontSize="base"
                            onClick={handleApproveAll}
                        >
                            {t("cookieSettingsScreen.acceptAllButton")}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CookieConsentModal;

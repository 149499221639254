import { ReactNode } from "react";
import Text from "../Text";
import ModalCloseButton, { ModalCloseButtonProps } from "./ModalCloseButton";

type Props = {
    title: ReactNode | ReactNode[];
    className?: string;
} & ModalCloseButtonProps;

const ModalHeader = ({ title, onClose, className = "" }: Props) => {
    return (
        <div className={`flex w-full justify-between pb-4 ${className}`}>
            <Text className="font-bold text-xl/6">{title}</Text>
            <ModalCloseButton onClose={onClose} />
        </div>
    );
};

export default ModalHeader;

import { getBackend } from "@/sites";
import axios from "axios";
import { getLanguageFromPath } from "../hooks/useLanguage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function createApiInstance(backendHost: string | undefined) {
    const apiInstance = axios.create({
        baseURL: backendHost,
        withCredentials: true,
        timeout: 200000,
    });

    apiInstance.defaults.headers.post["Content-Type"] = "application/json";

    return apiInstance;
}

export const apiInstance = createApiInstance(process.env.NEXT_PUBLIC_BACKEND_HOST);
apiInstance.interceptors.request.use(
    (config) => {
        const lang = getLanguageFromPath(window.location.pathname);
        config.baseURL = getBackend(lang);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export function handleErrors(error: unknown): Error {
    if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorBody = error.response.data;
                if (errorBody.error !== "UnauthorizedRequestError") {
                    toast.error(errorBody?.message || "An unexpected error occurred");
                }
                return new Error(errorBody?.message || "Error was thrown but 'message' property not in response body", {
                    cause: error,
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                reportError(error);
                toast.error("No response received from server");
                return error;
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                toast.error(error.message);
                return error;
            }
        } else {
            // Just a stock error
            reportError(error);
            toast.error("An error occurred");
        }
        console.error("Error occurred: ", error);
        return error;
    }
    toast.error("An unknown error occurred");
    return new Error("Error thrown is not of type error!");
}

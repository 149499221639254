import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    messages: string[];
    showX?: boolean;
}

const Alert = ({ messages, showX = false }: Props) => {
    return (
        <div className="rounded-md bg-red-50 p-4 mb-6">
            <div className="flex">
                {showX && (
                    <div className="flex-shrink-0 mr-3">
                        <FontAwesomeIcon className="h-5 w-5 text-red-400" aria-hidden="true" icon={faCircleX} />
                    </div>
                )}
                <div>
                    {/* <h3 className="text-sm font-medium text-red-800">There were 2 errors with your submission</h3> */}
                    <div className="text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                            {
                                messages.map((m) => <li key={m}>{m}</li>)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alert;
export type CheckBoxLabelTranslations = {
    essentialText: string;
    marketingText: string;
    statisticsText: string;
    externalMediaText: string;
};

export enum CheckboxesStates {
    Unknown = "UNKNOWN",
    Rejected = "REJECTED",
    Accepted = "ACCEPTED",
}

export type CookieCheckbokForm = {
    essential: CheckboxesStates;
    statistics: CheckboxesStates;
    marketing: CheckboxesStates;
    "external-media": CheckboxesStates;
};
"use client";

import Text from "@/lib/components/Text";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "@/lib/i18n/client";
import CookieConsentModal from "./CookieConsentModal";
import { CheckBoxLabelTranslations, CheckboxesStates, CookieCheckbokForm } from "./types";
import { faShieldHalved } from "@fortawesome/pro-solid-svg-icons";
import { getCookiePreferences } from "@/lib/utils/cookies";
import ReactDOM from "react-dom";
import { useAtom } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";

type CookieBannerProps = {
    lang: string;
    checkboxesLabels: CheckBoxLabelTranslations;
};

const CookieBanner = ({ lang, checkboxesLabels }: CookieBannerProps) => {
    const { t } = useTranslation(lang, "cookie-banner");
    const [checkBoxesForm, setCheckBoxesForm] = useState({
        essential: CheckboxesStates.Accepted,
        statistics: CheckboxesStates.Unknown,
        marketing: CheckboxesStates.Unknown,
        "external-media": CheckboxesStates.Unknown,
    } as CookieCheckbokForm);
    const [openModal, setOpenModal] = useState(false);
    const [showBanner, setshowBanner] = useState(false);
    const [auth, setAuth] = useAtom(authAtom);

    /**
     * Automatically checks all the boxes for
     * the cookie consent form
     */
    const handleSubmitApproveAll = () => {
        let currentState: CookieCheckbokForm;
        setCheckBoxesForm((prevState: CookieCheckbokForm) => {
            currentState = {
                ...prevState,
                essential: CheckboxesStates.Accepted,
                marketing: CheckboxesStates.Accepted,
                statistics: CheckboxesStates.Accepted,
                "external-media": CheckboxesStates.Accepted,
            };
            handleSubmitConsentInfo(currentState);
            return currentState;
        });
    };

    /**
     * Save all the consent data inside the cookies
     */
    const handleSubmitConsentInfo = (checkBoxFormData: CookieCheckbokForm) => {
        const selected: Array<string> = [];
        Object.keys(checkBoxFormData).forEach((labelKey: string) => {
            const value = checkBoxFormData[labelKey as keyof CookieCheckbokForm];
            if (value === CheckboxesStates.Accepted) {
                selected.push(labelKey);
            }
        });
        if (selected.includes("statistics")) {
            setAuth({ ...auth, analyticsEnabled: true });
        }
        document.cookie = "cookiePreferences=" + encodeURIComponent(selected.join(",")) + "; max-age=86400;Path=/;";
        setshowBanner(false);
        setOpenModal(false);
    };

    /**
     * Checking each time on the component mount if the cookies form
     * has been accepted mostly the mandatory essentials ones
     */
    useEffect(() => {
        const cookieConsentSetting = getCookiePreferences();
        if (cookieConsentSetting.includes("essential")) {
            setshowBanner(false);
        } else {
            setshowBanner(true);
        }
    }, []);

    if (showBanner) {
        return ReactDOM.createPortal(
            <Fragment>
                {openModal === false ? (
                    <div
                        className="fixed bottom-0 md:mb-0 max-lg:mb-4 max-lg:inset-x-6 z-40"
                        id="cookie-banner"
                        data-testid="cookie-banner"
                    >
                        <div className="bg-white w-full max-sm:max-w-screen-sm max-lg:rounded-xl max-md:border-2 md:max-lg:border-2">
                            <div className="max-w-full flex ga flex-col px-3 xl:px-40 lg:flex-row md:px-10 justify-center gap-x-2 md:gap-x-8 gap-y-4 h-full pt-6 pb-10">
                                <div className="relative block grow mx-2 md:mx-6 lg:mx-0 text-black md:ml-0">
                                    <Text as="span" className="font-bold mb-2 text-1.5xl md:text-4xl">
                                        {t("mainScreen.title")}
                                        <FontAwesomeIcon icon={faShieldHalved} style={{ color: "#14B5CB" }} size="sm" />
                                    </Text>
                                    <p>
                                        <Text className="font-medium text-sm md:text-lg">{t("mainScreen.text")}</Text>
                                    </p>
                                </div>
                                <div className="flex flex-col gap-3 lg:max-md:flex-wrap lg:flex-wrap w-full justify-center items-stretch">
                                    <Button
                                        onClick={handleSubmitApproveAll}
                                        variant="orange"
                                        className="font-bold md:font-medium text-sm md:text-xl"
                                    >
                                        {t("mainScreen.okayButton")}
                                    </Button>
                                    <Button
                                        onClick={() => setOpenModal(true)}
                                        variant="transparent"
                                        className="text-sm md:text-xl"
                                    >
                                        {t("mainScreen.settingsButton")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <CookieConsentModal
                    lang={lang}
                    isOpen={openModal}
                    checkBoxForm={checkBoxesForm}
                    setIsOpen={setOpenModal}
                    setShowCookieBanner={setshowBanner}
                    checkboxesLabels={checkboxesLabels}
                    handleSubmit={handleSubmitConsentInfo}
                    handleApproveAll={handleSubmitApproveAll}
                    setCheckBoxState={setCheckBoxesForm}
                />
            </Fragment>,
            document.querySelector("#myroom24-cookie-banner-portal")!,
        );
    } else {
        return null;
    }
};

export default CookieBanner;

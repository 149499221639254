"use client";

import Modal from "../Modal";
import Button from "../Button";
import { useTranslation } from "@/lib/i18n/client";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useAtom } from "jotai";
import { isForgotPasswordModalShownAtom } from "@/lib/jotai/auth/authStore";
import ModalHeader from "../Modal/ModalHeader";
import Text from "../Text";
import { Field, Form, Formik } from "formik";
import { API } from "@/lib/services/authService";
import { useState } from "react";
import Alert from "../Alert";

import * as Yup from "yup";

const ForgotPasswordModal = () => {
    const [open, setOpen] = useAtom(isForgotPasswordModalShownAtom);
    const lang = useLanguage();
    const { t } = useTranslation(lang, "login");
    const [errorMessage, setErrorMessage] = useState("");

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(t("invalid-email") || "")
            .required(t("field-required") || ""),
    });

    const onSubmit = async (values: { email: string }) => {
        try {
            await API.ForgotPassword(values);
            setOpen(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrorMessage(err.message);
            }
        }
    };

    return (
        <div className="text-3xl font-bold underline flex flex-col">
            <Modal open={open} mode="windowed" useDefaultContentPadding onClose={() => setOpen(false)}>
                <ModalHeader onClose={() => setOpen(false)} title={t("forgotPasswordModal.title")} />
                <div className="flex w-full flex-col justify-stretch">
                    <Text className="pb-2 text-left">{t("forgotPasswordModal.description")}</Text>
                    <Formik initialValues={{ email: "" }} validationSchema={ForgotPasswordSchema} onSubmit={onSubmit}>
                        {({ errors, touched }) => (
                            <Form className="flex flex-col gap-3">
                                <Field
                                    id="email"
                                    name="email"
                                    className={`grow ${errors.email && touched.email ? "border-red-600" : ""}`}
                                    type="email"
                                    placeholder={t("forgotPasswordModal.placeholder") || undefined}
                                />
                                {errors.email && touched.email && <Alert messages={[errors.email]}></Alert>}
                                <Button className="mt-2">{t("forgotPasswordModal.buttonSubmit")}</Button>
                                {errorMessage && <Alert messages={[errorMessage]} />}
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </div>
    );
};

export default ForgotPasswordModal;

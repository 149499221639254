"use client";

import { Fragment, ReactNode, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useWindowSize } from "@uidotdev/usehooks";

type ModalMode = "windowed" | "full-screen" | "full-height";

type Props = {
    open: boolean;
    setOpen?: (state: boolean) => void;
    children: ReactNode;
    mode?: ModalMode;
    useDefaultContentPadding?: boolean;
    onClose?: () => void;
    hideOnLg?: boolean;
    transparent?: boolean;
    customScroll?: boolean;
};

const Modal = ({
    open,
    setOpen,
    mode = "windowed",
    useDefaultContentPadding = false,
    onClose,
    children,
    hideOnLg = false,
    transparent = false,
    customScroll = false,
}: Props) => {
    let containerClassName = "";
    let contentClassName = "";
    const { height } = useWindowSize();
    switch (mode) {
        case "windowed":
            containerClassName = "p-0 md:p-4";
            contentClassName = "rounded-lg w-11/12 xl:w-1/2 lg:w-3/4";
            break;
        case "full-screen":
            contentClassName = "w-full";
            break;
        case "full-height":
            contentClassName = "w-full h-full";
            break;
    }

    if (useDefaultContentPadding) {
        contentClassName = `${contentClassName} p-4 lg:pd-5`;
    }

    useEffect(() => {
        if (!open) {
            // this hack prevents safari to scroll to the bottom when we close dialog, probably related to https://bugs.webkit.org/show_bug.cgi?id=257217
            window.focus();
            if (document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
        }
    }, [open]);

    const closeModal = (state: boolean) => {
        // this hack prevents safari to scroll to the bottom when we close dialog, probably related to https://bugs.webkit.org/show_bug.cgi?id=257217
        window.focus();
        if (document.activeElement) {
            (document.activeElement as HTMLElement).blur();
        }

        if (setOpen) {
            setOpen(state);
        }
        if (onClose) {
            onClose();
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className={`relative z-30 ${hideOnLg && "lg:hidden"}`} onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className={`fixed inset-0 transition-opacity ${transparent ? "bg-darkBlack opacity-90" : "bg-gray-500 bg-opacity-75"
                            }`}
                    />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className={`flex min-h-full justify-center  text-center items-center ${containerClassName}`}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-0 md:translate-y-4 scale-95 md:scale-100"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                style={mode === "full-screen" && height ? { height: height } : {}}
                                className={`relative transform ${transparent ? "" : "bg-white"
                                    } shadow-xl transition-all overflow-y-auto ${contentClassName} ${customScroll ? "overflow-hidden flex flex-col" : "overflow-y-auto"
                                    }`}
                            >
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Modal;

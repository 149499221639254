import { atom } from "jotai";
import { UserInfo } from "./types";
import { atomWithStorage } from "jotai/utils";

export const isLoginModalShownAtom = atom(false);
export const isForgotPasswordModalShownAtom = atom(false);

export const initialUserInfo: UserInfo = {
    _id: "",
    account: {
        __v: 0,
        _id: "",
        createdAt: "",
        email: "",
        emailVerified: false,
        phoneNumberVerified: false,
        updatedAt: "",
    },
    firstName: "",
    lastName: "",
    phoneNumbers: [],
    gdprConfirmed: false,
    verified: false,
    status: "",
    profilePicture: "",
    language: "",
    kind: "Guest",
    createdAt: "",
    updatedAt: "",
    __v: 0,
    roles: [],
    stripeCustomer: "",
    address: {
        city: "",
        country: "",
        number: "",
        street: "",
        zipCode: "",
        additionalAddressLine: "",
    },
    companyName: "",
    taxNumber: "",
    isBusiness: false,
    businessId: "",
};

export type UserAtom = {
    isLoggedIn: boolean;
    analyticsEnabled: boolean;
    userInfo: UserInfo;
};

export const authAtom = atomWithStorage<UserAtom>("userInfo", {
    isLoggedIn: false,
    analyticsEnabled: false,
    userInfo: initialUserInfo,
});

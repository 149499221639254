"use client";

import { classNames } from "@/lib/utils/general";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useRef, useState } from "react";

type Direction = "left" | "right";
type Cursor = "cursor-default" | "cursor-pointer" | "cursor-not-allowed";
export type InputState = "valid" | "error" | "none";

export type InputProps = {
    customHeight?: string;
    customPadding?: string;
    hideTypeNumberSpinButtons?: boolean;
    useDefaultBorderStyles?: boolean;
    icon?: IconProp;
    iconDirection?: Direction;
    onIconClick?: () => void;
    wrapperClassName?: string;
    errorMessage?: string;
    status?: InputState;
    cursorClass?: Cursor;
    noRequiredAsterisk?: boolean;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            useDefaultBorderStyles = true,
            customPadding = "p-2",
            hideTypeNumberSpinButtons = false,
            customHeight = "h-14",
            className = "",
            icon,
            iconDirection,
            onIconClick,
            wrapperClassName,
            onFocus,
            onBlur,
            errorMessage,
            placeholder,
            status = "none",
            type,
            cursorClass = "cursor-default",
            noRequiredAsterisk = false,
            ...rest
        },
        ref,
    ) => {
        const [isFocused, setIsFocused] = useState(false);
        const isPasswordField = type === "password";
        const [isPasswordVisible, setIsPasswordVisible] = useState(false);
        const isPlaceholder = placeholder && placeholder !== "" ? "pb-0" : "py-0";
        const refInput = useRef<HTMLInputElement | null>();

        const defaultCss = "";
        switch (type) {
            default:
                break;
        }

        const getLabelCss = () => {
            let holderClass;

            if (isFocused || !!rest.value) {
                holderClass = classNames(isFocused && "text-cyan-500", "ml-3 -top-1 text-xxs");
            } else {
                holderClass = `text-black text-base ml-3 mt-1`;
            }
            return holderClass;
        };

        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(true);
            if (onFocus) {
                onFocus(e);
            }
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);
            if (onBlur) {
                onBlur(e);
            }
        };

        const calcBorderColorWithStatus = () => {
            // in case we have error message, input is in error state
            if (errorMessage) {
                return "border-red-500";
            }

            switch (status) {
                case "none":
                    return "border-gray-300";
                case "valid":
                    return "border-green-500";
                case "error":
                    return "border-red-500";
            }
        };

        return (
            <span
                className={`${customHeight} ${
                    rest.disabled ? "bg-gray-100" : "bg-white"
                } flex flex-col text-base justify-center items-stretch ${customPadding} group ${wrapperClassName} ${
                    useDefaultBorderStyles && "rounded-md"
                } text-black placeholder-black border ${calcBorderColorWithStatus()} focus:outline-none focus-within:border-cyan-500 ${defaultCss} ${
                    className ?? ""
                }`}
                onClick={() => refInput?.current?.focus()}
            >
                <span className="flex flex-row items-center">
                    {icon && iconDirection === "left" && (
                        <FontAwesomeIcon
                            icon={icon}
                            className={classNames(
                                "transition-colors duration-300 ease-in-out focus:text-cyan-500",
                                isFocused && "text-cyan-500",
                            )}
                            onClick={onIconClick}
                        />
                    )}
                    <span className="w-full relative">
                        <span
                            className={classNames(
                                getLabelCss(),
                                "absolute font-medium transition-all duration-300 ease-in-out pointer-events-none text-black",
                            )}
                        >
                            {rest.required && !noRequiredAsterisk ? `* ${placeholder}` : placeholder}
                        </span>
                        <input
                            type={isPasswordField ? (isPasswordVisible ? "text" : type) : type}
                            className={classNames(
                                isPlaceholder,
                                cursorClass,
                                "w-full h-full border-0 focus:border-0 outline-0 focus:ring-0 ",
                                "disabled:bg-gray-100",
                                " focus:placeholder-cyan-500 placeholder:text-black placeholder:font-medium ",
                                hideTypeNumberSpinButtons
                                    ? " [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                                    : " ",
                            )}
                            {...rest}
                            ref={(r) => {
                                if (ref && typeof ref === "function") {
                                    ref(r);
                                } else if (ref) {
                                    ref!.current = r;
                                }
                                if (refInput) {
                                    refInput.current = r;
                                }
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                        {errorMessage && (
                            <span className={classNames("absolute right-0 -bottom-2 font-medium text-red-500")}>
                                {errorMessage}
                            </span>
                        )}
                    </span>
                    {icon && iconDirection === "right" && (
                        <FontAwesomeIcon
                            icon={icon}
                            className={`transition-colors  duration-300 ease-in-out focus:text-cyan-500`}
                            onClick={onIconClick}
                        />
                    )}
                    {isPasswordField && !isPasswordVisible && (
                        <FontAwesomeIcon
                            icon={faEye}
                            className={`transition-colors  duration-300 ease-in-out hover:text-cyan-500 cursor-pointer`}
                            onClick={() => setIsPasswordVisible(true)}
                        />
                    )}
                    {isPasswordField && isPasswordVisible && (
                        <FontAwesomeIcon
                            icon={faEyeSlash}
                            className={`transition-colors  duration-300 ease-in-out hover:text-cyan-500 cursor-pointer`}
                            onClick={() => setIsPasswordVisible(false)}
                        />
                    )}
                </span>
            </span>
        );
    },
);

Input.displayName = "Input";
export default Input;

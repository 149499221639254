import { apiInstance, handleErrors } from "./api";
import { ENDPOINTS } from "../constants/endpoints";
import { UserInfo } from "../jotai/auth/types";
import { RegistrationFormData } from "./registrationService";
import { BecomeAHostFormData } from "./becomeAHostService";

export type LoginFormData = {
    email: string | undefined;
    password: string | undefined;
};

type LoginRequestData = LoginFormData;

type LoginResponseSuccess = UserInfo;

type ForgotPasswordData = {
    email: string;
};

export type ChangePasswordData = {
    email: string;
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
};

export type VerifyEmailData = {
    token: string;
};

export type ResetPasswordData = {
    token: string;
    password: string;
};

type HostCompanyData = {
    name: string;
    taxNumber: string;
    isBusiness: boolean;
    businessId: string;
};

type HostAccountData = {
    email: string;
    password: string;
};

type HostPropertyAddress = {
    city: string;
    street: string;
    country: string;
    number: string;
    zipCode: string;
};

type HostPropertyLocation = {
    type: string;
    coordinates: number[];
};

type HostData = {
    account: HostAccountData;
    firstName: string;
    lastName: string;
    gdprConfirmed: boolean;
    language: string;
    phoneNumbers: string[];
};

type HostPropertyData = {
    address: HostPropertyAddress;
    location: HostPropertyLocation;
    name: string;
};

type HostRegistrationData = {
    companyData: HostCompanyData;
    hostData: HostData;
    propertyData: HostPropertyData;
};

type SendStorageData = {
    userInfo: object;
    redirectUri: string;
    checkoutForms: object;
};

type StorageResponse = {
    token: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace API {
    export async function Login(data: LoginRequestData): Promise<LoginResponseSuccess> {
        try {
            const response = await apiInstance.post(ENDPOINTS.LOGIN, data);
            return response.data as LoginResponseSuccess;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function GoogleLogin(tokenId: string): Promise<LoginResponseSuccess> {
        try {
            const endpoint = `${ENDPOINTS.GOOGLE_LOGIN}?id_token=${tokenId}`;
            const response = await apiInstance.get(endpoint);
            return response.data as LoginResponseSuccess;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function VerifyEmail(data: VerifyEmailData) {
        try {
            await apiInstance.post(ENDPOINTS.VERIFY_EMAIL.replace("{token}", data.token), data);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function ChangePassword(data: ChangePasswordData) {
        try {
            await apiInstance.patch(ENDPOINTS.CHANGE_PASSWORD, data);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function ResetPassword(data: ResetPasswordData): Promise<LoginResponseSuccess> {
        try {
            const response = await apiInstance.post(ENDPOINTS.RESET_PASSWORD, data);
            return response.data as LoginResponseSuccess;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function GenerateSMSValidationCode() {
        try {
            await apiInstance.get(ENDPOINTS.PHONE_VALIDATION);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function VerifiySMSCode(code: string) {
        try {
            await apiInstance.post(`${ENDPOINTS.PHONE_VALIDATION}/${code}`);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function ForgotPassword(data: ForgotPasswordData) {
        try {
            await apiInstance.post(ENDPOINTS.FORGOT_PASSWORD, data);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function GetUserInfo(): Promise<UserInfo> {
        try {
            const response = await apiInstance.get(ENDPOINTS.USERINFO);
            return response.data as UserInfo;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function GetUserInfoToken(token: string): Promise<UserInfo> {
        try {
            const response = await apiInstance.post(ENDPOINTS.TOKEN_LOGIN, {
                token: token
            });
            return response.data as UserInfo;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function Logout() {
        try {
            await apiInstance.delete(ENDPOINTS.LOGIN);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function RegisterGuest(data: RegistrationFormData) {
        try {
            await apiInstance.post(ENDPOINTS.REGISTER_GUEST, data, {
                params: {
                    redirectUrl: window.location.pathname + window.location.search,
                },
            });
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function RegisterHost(data: BecomeAHostFormData) {
        try {
            const submissionData: HostRegistrationData = {
                companyData: {
                    name: data.companyName ?? "",
                    taxNumber: data.taxId ?? "",
                    isBusiness: data.isBusiness ?? false,
                    businessId: data.businessId ?? "",
                },
                hostData: {
                    account: {
                        email: data.email ?? "",
                        password: data.password ?? "",
                    },
                    firstName: data.firstName ?? "",
                    lastName: data.lastName ?? "",
                    gdprConfirmed: true,
                    phoneNumbers: [data.phoneNumber ?? ""],
                    language: data.language,
                },
                propertyData: {
                    address: {
                        city: data.city ?? "",
                        country: data.country ?? "",
                        number: "",
                        street: "",
                        zipCode: "",
                    },
                    location: {
                        type: "Point",
                        coordinates: [0, 0],
                    },
                    name: data.nameOfAccommodation ?? "",
                },
            };
            await apiInstance.post(ENDPOINTS.REGISTER_HOST, submissionData);
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function SendStorage(data: SendStorageData) {
        try {
            const response = await apiInstance.post(ENDPOINTS.STORAGE, data);
            return response.data as StorageResponse;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }
}
